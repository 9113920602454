let URI = {
    'getHotelList': '/customerlogin/hotel_list_userid',
    'getHotelLists': '/hotel_Api/hotel_list',

    //Notification:
    'removeNotificationlist': '/admin_dashboard/clear_notification_list',
    'viewNotificationlist': '/admin_dashboard/view_all_list_notification',
    'getNotificationList': '/admin_dashboard/list_notification',
    'clearNotificationByUser': '/admin_dashboard/clear_notification_by_user',
    'getNewBookingNotificationList': '/admin_dashboard/new_booking_notification_list',

    //Reports
    'getGuestTypeStatistics': '/admin_dashboard/GuestTypeStatictic',
    'getNationalityStatistics': '/admin_dashboard/Nationality',
    'getMealPlanStatistics': '/admin_dashboard/ArrivalTomorrowByMealPlan',
    'getGuestTypeStatistics1': '/admin_dashboard/guesttype_statistics',


    //Settings:
    'getSettingView': '/SettingsGeneral_Api/View',
    'SettingUpdate': '/SettingsGeneral_Api/Update',

    //Hotel
    'CreatHotel': '/hotel_Api/create_hotel',
    'UpdateHotel': '/hotel_Api/update_hotel',
    'DeleteHotel': '/hotel_Api/delete_Hotel',
    'ViewHotel': '/hotel_Api/view_hotel',


    // Dashboard
    'getAllwidgetList': '/widgets/widgets_list',
    'updateWidget': '/widgets/update_widgets',
    'updateAllWidgets': '/widgets/update_all_widgets_isactive1',
    'getUserWidgetList': '/userwidgets/user_widgets_list',
    'updateUserWidget': '/userwidgets/update_user_widgets',

    // Dashboard Widgets
    'getLatestReviews': '/admin_dashboard/lastest_reviews',
    'getAverageRatings': '/admin_dashboard/overall_average_ratings',
    'getArrivingList': '/admin_dashboard/today_arriving_list',
    'getDepartingList': '/admin_dashboard/today_departure_list',

    'getTopCountries': '/admin_dashboard/top_country_list',
    'getMobileDesktops': '/admin_dashboard/mobile_desktop_Counts',
    'getCheckinDetails': '/admin_dashboard/total_countof_checkin_details',
    'getRoomOccupancy': '/admin_dashboard/room_occupancy',
    'getSLAStatistics': '/admin_dashboard/sla_statistics',
    'getBookingStatistics': '/admin_dashboard/booking_statistics',
    'getAlertSLA': '/admin_dashboard/top10_slabooking_list',


    // CMS URI
    'getPageList': '/page/tree_viewlist_pagemaster',
    'getContent': '/pagecontent_Api/view_pagecontent_by_pageId_languageid',
    'createContent': '/pagecontent_Api/create_page_content',
    'updateContent': '/pagecontent_Api/update_page_content',
    'createPageInfo': '/page/create_page',
    'updatePageInfo': '/page/edit_page',
    'deletePageInfo': '/page/delete_page',
    'updatePageOrder': '/page/treeview_update_page',

    // Policy
    'GetPolicyType': '/policy_Api/get_policy_types_list',
    'GetPolicyContent': '/policy_Api/policy_content',
    'createPolicyContent': '/policy_Api/create_policy',
    'updatePolicyContent': '/policy_Api/update_policy',

    // Disclaimer
    'GetDisclaimerType': '/disclaimer/get_disclaimer_types_list',
    'GetDisclaimerContent': '/disclaimer/view_disclaimer_content',
    'CreateDisclaimerContent': '/disclaimer/create_disclaimer',
    'UpdateDisclaimerContent': '/disclaimer/update_disclaimer',

    //Spa
    'getKanbanBoardSpaList': '/SpaBooking_Api/KanbanSpaBooking_list',
    'spaBookingChangeStatus': '/SpaBooking_Api/update_kanbanStatus_SpaBooking',
    'spaBookingRemarksupdate': '/SpaBooking_Api/update_kanbanRemarks_SpaBooking',
    'viewSpaBooking': '/SpaBooking_Api/view_SpaBooking',
    'getStatusList': '/RestaurantBooking/ddlRestaurantBookingStatus_list',
    'getSpaPackageList': '/SpaBooking_Api/ddlSpaPackages_list',
    'getSpaBookingList': '/SpaBooking_Api/SpaBooking_list',
    'addSpaBooking': '/SpaBooking_Api/create_SpaBooking',
    'editSpaBooking': '/SpaBooking_Api/update_SpaBooking',
    'deleteSpaBooking': '/SpaBooking_Api/delete_SpaBooking',
    'getUserList': '/mobile_user/get_mobile_user_list',
    'getTherapistList': '/therapist/therapist_list',
    'getddlTherapistList': '/therapist/ddltherapist_list',
    'GetSPAPackageList': '/spapackage/spa_package_list',
    'CreateSPAPackage': '/spapackage/create_spa_package',
    'EditSPAPackage': '/spapackage/update_spa_package',
    'ViewSPAPackage': '/spapackage/view_spa_package',
    'DeleteSPAPackage': '/spapackage/delete_spa_package',
    'GetCategoriesList': '/spapackage/ddlSpaPackageCategory_list',
    'GetSPALanguageList': '/spapackage/cms_spa_package_language_list',
    'GetSPALanguageContent': '/spapackage/cms_view_spa_package_language',
    'CreateSPALanguageContent': '/spapackage/cms_create_spa_package_language',
    'UpdateSPALanguageContent': '/spapackage/cms_edit_spa_package_language',
    'getAvailableTherapist': '/therapist/available_therapist_list',
    'UpdateTherapist': '/SpaBooking_Api/Update_thrapist_SpaBooking',
    'getAmount': '/SpaBooking/GetAmountBySpaPackageId',
    'getTherapistAvailabilityList': '/therapist/therapist_availability_calenderlist',
    'GetCMSSpaPackageCategoryList': '/spa_packagecategory/CMSList',
    'GetCMSSpaPackageCategoryContent': '/spa_packagecategory/CMSView',
    'CreateCMSSpaPackageCategoryContent': '/spa_packagecategory/CMSCreate',
    'UpdateCMSSpaPackageCategoryContent': '/spa_packagecategory/CMSEdit',
    'getcategoryList': '/spa_packagecategory/spa_packagecategory_list',
    'createCategoryList': '/spa_packagecategory/create_spa_packagecategory',
    'updateCategoryList': '/spa_packagecategory/edit_spa_packagecategory',
    'viewCategoryList': '/spa_packagecategory/view_spa_packagecategory',
    'deleteCategoryList': '/spa_packagecategory/delete_spa_packagecategory',





    //Restaurant
    'getRestaurantList': '/restaurant/restaurant_list',
    'addRestaurant': '/restaurant/create_restaurant',
    'editRestaurant': '/restaurant/edit_restaurant',
    'viewRestaurant': ' `/restaurant/view_restaurant?Restaurant=`+data.RestaurantId',
    'deleteRestaurant': '/restaurant/delete_restaurant',
    'getRestaurantBookingList': '/RestaurantBooking/RestaurantBooking_list',
    'addRestaurantBooking': '/RestaurantBooking/create_RestaurantBooking',
    'editRestaurantBooking': '/RestaurantBooking/update_RestaurantBooking',
    'viewRestaurantBooking': '/RestaurantBooking/view_RestaurantBooking',
    'deleteRestaurantBooking': '/RestaurantBooking/delete_RestaurantBooking',
    'getRestaurantListddl': '/RestaurantBooking/ddlRestaurant_list',
    'StatusList': '/RestaurantBooking/ddlRestaurantBookingStatus_list',
    'getKanbanboardList': '/RestaurantBooking/KanabanRestaurantBooking_list',
    'restaurantBookingChangeStatus': '/RestaurantBooking/update_kanbanStatus_RestaurantBooking',
    'restaurantBookingRemarksupdate': '/RestaurantBooking/update_kanbanRemarks_RestaurantBooking',
    'getRestaurantUserLists': '/mobile_user/in_house_mobile_user_list',
    'getGuestname': '/mobile_user/get_guestname_mobileno_by_userid',
    'getGuestnameByCheckin': '/mobile_user/get_guestname_mobileno_by_checkinid',
    'getPagesList': '/page/page_list',

    //Bike
    'getBikeList': '/bike/bike_list',
    'addBike': '/bike/create_bike',
    'editBike': '/bike/update_bike',
    'viewBike': '/bike/view_bike',
    'deleteBike': '/bike/delete_Bike',
    'getBikeBookingList': '/BikeBooking/List_Calender_BikeBooking',
    'addBikeBooking': '/BikeBooking/Create_Calender_BikeBooking',
    'editBikeBooking': '/BikeBooking/Update_Calender_BikeBooking',
    'viewBikeBooking': '/BikeBooking/view_Calender_BikeBooking',
    'deleteBikeBooking': '/BikeBooking/Delete_Calender_BikeBooking',
    'getBikeListddl': '/BikeBooking/ddlBike_list',
    'getBikeKanbanboardList': '/BikeBooking/KanbanBikeBooking_list',
    'bikeBookingChangeStatus': '/BikeBooking/update_kanbanStatus_BikeBooking',
    'bikeBookingRemarksupdate': '/BikeBooking/update_kanbanRemarks_BikeBooking',



    //Reviews & Ratings
    'getRatingQuestionsList': '/ratingquestions/rating_questions_list',
    'createRatingQuestions': '/ratingquestions/create_rating_questions',
    'updateRatingQuestions': '/ratingquestions/update_rating_question',
    'deleteRatingQuestions': '/ratingquestions/delete_rating_question',
    'viewRatingQuestions': '/ratingquestions/view_rating_question',
    'viewManageQuestions': '/ratingquestions/view_manage_question',
    'updateManageQuestions': '/ratingquestions/update_manage_question',
    'getModuleList': '/module/moduel_list',
    'viewManageQuestion': '/ratingquestions/view_manage_question',
    'updateManageQuestion': '/ratingquestions/update_manage_question',
    'getCmsRatingQueList': '/ratingquestions/cms_rating_question_language_list',
    'addCmsRatingQue': '/ratingquestions/cms_create_rating_question_language',
    'viewCmsRatingQue': '/ratingquestions/cms_view_rating_question_language',
    'updateCmsRatingQue': '/ratingquestions/cms_edit_rating_questions_language',
    'getReviewsList': '/admin_dashboard/user_review_rating_list',
    'viewRating': '/user_review/view_user_review_rating',
    'deleteRating': '/user_review/delete_user_review_rating',



    //Task
    'getTaskList': '/admin_dashboard/task_review_rating_list',
    'getAdminList': '/user/admin_user_list?HotelId=',
    'updateTaskAssignTo': '/task_rating/update_task_assingto',
    'deleteTask': '/task_rating/delete_task_rating',
    'getStatus': '/task_rating/task_status_list',
    'updateTask': '/task_rating/update_task',
    'viewTask': '/task_rating/view_task_rating',

    //Boat
    'getBoatList': '/boat/boat_list',
    'addBoat': '/boat/create_boat',
    'updateBoat': '/boat/update_boat',
    'viewBoat': '/boat/view_boat',
    'deleteBoat': '/boat/delete_boat',
    'getBoatBookingServerSideList': '/BoatBooking/Boat_Booking_Server_Side_list',
    'getBoatKanbanBoardList': '/BoatBooking/KanbanBoatBooking_list',
    'boateBookingChangeStatus': '/BoatBooking/update_kanbanStatus_BoatBooking',
    'boatBookingRemarksupdate': '/BoatBooking/update_kanbanRemarks_BoatBooking',
    'getBoatCalendarList': '/BoatBooking/BoatBooking_list',
    'addBoatBooking': '/BoatBooking/create_boat_booking',
    'editBoatBooking': '/BoatBooking/update_boat_booking',
    'deleteBoatBooking': '/BoatBooking/delete_BoatBooking',
    'viewBoatBooking': '/BoatBooking/view_BoatBooking',
    'DurationUnitList': '/boat_house_activity/ddl_duration_unit_list',
    'addBoatHouseActivity': '/boat_house_activity/create_boat_house_activity',
    'editBoatHouseActivity': '/boat_house_activity/update_boat_house_activity',
    'getBoatHouseActivityList': '/boat_house_activity/boat_house_activity_list',
    'viewBoatHouseActivity': '/boat_house_activity/view_boat_house_activity',
    'deleteBoatHouseActivity': '/boat_house_activity/delete_boat_house_activity',
    'cmsaddBoatHouseActivity': '/boat_house_activity/cms_create_boat_house_activity_language',
    'cmseditBoatHouseActivity': '/boat_house_activity/cms_edit_boat_house_activity_language',
    'cmsviewBoatHouseActivity': '/boat_house_activity/cms_view_boat_house_activity_language',
    'cmsBoatHouseActivityList': '/boat_house_activity/cms_boat_house_activity_language_list',
    'getSessionList': '/session/Session_list',
    'addSession': '/session/create_session',
    'editSession': '/session/update_session',
    'viewSession': '/session/view_session',
    'deleteSession': '/session/delete_session',


    //Amenity
    'getAmenityList': '/Amenity/amenity_list',
    'addAmenity': '/Amenity/create_amenity',
    'editAmenity': '/Amenity/update_amenity',
    'viewAmenity': '/Amenity/view_amenity',
    'deleteAmenity': '/Amenity/delete_amenity',
    'getcmsAmenityLangList': '/Amenity/cms_amenity_language_list',
    'addcmsAmenity': '/Amenity/cms_create_amenity_language',
    'editcmsAmenity': '/Amenity/cms_edit_amenity_language',
    'viewcmsAmenity': '/Amenity/cms_view_amenity_language',
    'getAmenityBookingList': '/AmenityBooking/AmenityBooking_list',
    'addAmenityBooking': '/AmenityBooking/Create_Calender_AmenityBooking',
    'editAmenityBooking': '/AmenityBooking/Update_Calender_AmenityBooking',
    'viewAmenityBooking': '/AmenityBooking/View_AmenityBooking',
    'deleteAmenityBooking': '/AmenityBooking/Delete_Calender_AmenityBooking',
    'getAmenityKanbanList': '/AmenityBooking/KanbanAmenityBooking_list',
    'amenityBookingStatusChange': '/AmenityBooking/update_kanbanStatus_AmenityBooking',
    'amenityRamarksUpdate': '/AmenityBooking/update_kanbanRemarks_AmenityBooking',

    //RoomService
    'getddlRoomServiceBookingApprovalStatuslist': '/room_service_booking/ddlRoomServiceBooking_ApprovalStatus_list',
    'addRoomServiceBooking': '/room_service_booking/create_RoomServiceBooking',
    'editRoomServiceBooking': '/room_service_booking/update_RoomServiceBooking',
    'viewRoomServiceBooking': '/room_service_booking/view_RoomServiceBooking',
    'getRoomServiceBookingList': '/room_service_booking/RoomServiceBooking_list',
    'deleteRoomServiceBooking': '/room_service_booking/delete_RoomServiceBooking',
    'GetRoomServiceList': '/RoomServices/room_services_list',
    'getRoomServiceKanbanBoardList': '/room_service_booking/KanbanRoomServiceBooking_list',
    'RoomServicestatusChange': '/room_service_booking/update_kanbanStatus_RoomServiceBooking',
    'RoomServiceRemarksUpdate': '/room_service_booking/update_kanbanRemarks_RoomServiceBooking',
    'AddServiceAvailability': '/service_type/add_service_type',
    'EditServiceAvailability': '/service_type/edit_service_type',

    //CheckInDetails
    'getcheckindetailsList': '/check_in_details/list_CheckInDetail',
    'addCheckInDetails': '/check_in_details/create_CheckInDetail',
    'editCheckInDetails': '/check_in_details/update_CheckInDetail',
    'deleteCheckInDetails': '/check_in_details/delete_CheckInDetail',
    'viewCheckInDetails': '/check_in_details/view_CheckInDetail',
    'getddlCountryList': '/check_in_details/ddlCountry_list',

    // ADMIN
    'getAdminDTList': '/user/user_list',
    'getAdminCategory': '/module/moduel_list',
    'getAdminRole': '/user/user_role_list',
    'createAdminUser': '/user/create_user',
    'getAdminUserById': '/user/view_user',
    'updateAdminUser': '/user/update_user',
    'deleteAdminUser': '/user/delete_user',

    //Customers
    'createCustomer': '/mobile_user/add_mobileuser',
    'updateCustomer': '/mobile_user/update_mobileuser',
    'deleteCustomer': '/mobile_user/delete_mobileuser',

    // Role
    'getRoleList': '/user_role/user_role_list',
    'createRole': '/user_role/create_user_role',
    'getPermission': '/user_role/permission_list',
    'viewUserRole': '/user_role/view_user_role_permission',
    'updateUserRole': '/user_role/update_user_role',
    'deleteUserRole': '/user_role/delete_user_role',

    //TherapistLeave
    'getTherapistLeaveList': '/therapist_leave/therapist_leave_list',
    'addTherapistLeave': '/therapist_leave/create_therapist_leave',
    'deleteTherapistLeave': '/therapist_leave/delete_therapist_leave',
    'editTherapistLeave': '/therapist_leave/update_therapist_leave',
    'ViewTherapistLeave': '/therapist_leave/view_therapist_leave',

    // PageContent
    'pageContentList': '/pagecontent_Api/page_content_list',


    'getPermissionsOfUser': '/customerlogin/user_permission_list',

    //Geo Location
    'getGeoLocationList': '/GeoLocation/geolocation_list',
    'addGeoLocation': '/GeoLocation/create_geolocation',
    'editGeoLocation': '/GeoLocation/update_geolocation',
    'viewGeoLocation': '/GeoLocation/view_geolocation',
    'deleteGeoLocation': '/GeoLocation/delete_geolocation',
    'getLanguageList': '/languages/language_list',
    'getTypeList': '/GeoLocation/ddlType_list',


    //Beacon Message
    'getBeaconMessageList': '/BeaconMessage/beacon_message_list',
    'addBeaconMessage': '/BeaconMessage/create_beacon_message',
    'editBeaconMessage': '/BeaconMessage/update_beacon_message',
    'viewBeaconMessage': '/BeaconMessage/view_beacon_message',
    'deleteBeaconMessage': '/BeaconMessage/delete_beacon_message',

    // User profile
    'forgotPassword': '/customerlogin/forget_password',
    'resetPassword': '/customerlogin/reset_password',


    //Phone directory
    'getPhoneDirectoryList': '/phonedirectory/cms_phonedirectory_language_list',
    'GetPhoneDirectoryLanuageContent': '/phonedirectory/cms_view_phonedirectory_language',
    'getCategoryList': '/phonedirectory/ddlPhoneDirectory_Category_list',
    'addPhoneDirectory': '/phonedirectory/cms_create_phonedirectory_language',
    'editPhoneDirectory': '/phonedirectory/cms_update_phonedirectory_language',
    'masterAddPhoneDirectory': '/phonedirectory/create_phonedirectory',
    'masterListPhoneDirectory': '/phonedirectory/phone_directory_list',
    'masterEditPhoneDirectory': '/phonedirectory/update_phonedirectory',
    'masterViewPhoneDirectory': '/phonedirectory/view_phonedirectory',



    //Service Tabbar
    'getServiceTabbarList': '/service_tabbar/cms_service_tabbar_language_list',
    'addServiceTabbar': '/service_tabbar/cms_create_service_tabbar_language',
    'editServiceTabbar': '/service_tabbar/cms_update_service_tabbar_language',
    'viewServiceTabbar': '/service_tabbar/cms_view_service_tabbar_language',

    //Place Of Interest
    'create_placeofinterest': '/placeofinterest/create_placeofinterest',
    'edit_placeofinterest': '/placeofinterest/edit_placeofinterest',
    'delete_deleteinterest': '/placeofinterest/delete_placeofinterest',

    'GetCMSPlaceOfInterestList': '/PlaceOfInterest/CMSListPlaceOfInterestLanguageMaster',
    'GetPlaceOfInterestLanguageContent': '/PlaceOfInterest/CMSViewPlaceOfInterestLanguageMaster',
    'CreatePlaceOfInterestLanguageContent': '/PlaceOfInterest/CMSCreatePlaceOfInterestLanguageMaster',
    'UpdatePlaceOfInterestLanguageContent': '/PlaceOfInterest/CMSEditPlaceOfInterestLanguageMaster',

    //SLA
    'view_managesla': '/ratingquestions/view_manage_sla',
    'updateManageSla': '/ratingquestions/update_manage_sla',
    'viewSlaEmail': '/email_template/view_template_list',
    'updateSlaEmailTemplate': '/email_template/update_email_template',
    'createSlaEmailTemplate': '/email_template/create_email_template',
    //Chart Report
    'dashboard_chart_report': '/dashboard/dashboard_chart_report',
    'getModuleFieldList': '/email_template/get_all_template_params',

    //Manage Cabin
    'getCabinList': '/cabin/cabin_list',
    'deleteCabin': '/cabin/delete_cabin',
    'viewCabin': '/cabin/view_cabin',
    'createCabin': '/cabin/create_cabin',
    'updateCabin': '/cabin/update_Cabin',
    'cabinAvailabilityCalendar': '/cabin/cabin_availability_calenderlist',
    'availableCabinList': '/cabin/available_cabin_list',

    //Cabin Language
    'GetCMSSpaCabinList': '/cabin/cms_cabin_language_list',
    'GetCMSSpaCabinContentView': '/cabin/cms_view_cabin_language',
    'CreateCMSSpaCabinContent': '/cabin/cms_create_cabin_language',
    'UpdateCMSSpaCabinContent': '/cabin/cms_edit_cabin_language',

    //Splash Content Name
    'getSplashContenthList': '/SplashContent/SplashContent_List',
    'splashContenthView': '/SplashContent/view_splash',
    'splashContenthCreate': '/SplashContent/create_Splash',
    'splashContenthUpdate': '/SplashContent/update_splash',
    'splashContenthDelete': '/SplashContent/delete_splash',


    //Splash Content Language
    'getSplashLangList': '/splashcontent/cms_splashcontent_language_list',
    'splashLangCreate': '/splashcontent/cms_create_splashcontent_language',
    'splashLangUpdate': '/splashcontent/cms_edit_splashcontent_language',
    'splashLangView': '/splashcontent/cms_view_splashcontent_language',
 // new added delete
    'deleteserviceavailability':'/service_type/delete_service_type'
}
export { URI };