import { Component, OnInit } from '@angular/core';
import {AppConfig} from "config";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  hotelName;
  constructor() { 
    
  }

  ngOnInit() {
    this.hotelName = AppConfig.app_name;
  }

}
