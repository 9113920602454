import { Component, OnInit } from '@angular/core';

import { quicklinks } from './data';

@Component({
  selector: 'app-rightsidebar',
  templateUrl: './rightsidebar.component.html',
  styleUrls: ['./rightsidebar.component.scss']
})
export class RightsidebarComponent implements OnInit {

  quicklinks: any;

  constructor() { }

  ngOnInit() {
    /**
     * fetches data
     */
    this._fetchData();
  }

  /**
   * Hide the sidebar
   */
  public hide() {
    document.body.classList.remove('right-bar-enabled');
  }

  /**
   * fetches the inbox value
   */
  private _fetchData() {
    let permissions = JSON.parse(localStorage.getItem("PermissionList"));

    this.quicklinks = quicklinks.filter(function (value) {
      return permissions.includes(value.slug_name);
    }, permissions);
  }
}
